import React from 'react'
import { useParams, Link } from 'react-router-dom'
//import videos from '../../db/video-records-db.json'

import { Badge, Table, Container } from 'react-bootstrap'
import style from './NagranieOne.module.css'

export default function NagranieOne({videos}) {
    const {id} = useParams()
    return (
        <Container className={`${style.nagranideBox} px-0`}>
            {
                videos && videos.map(video => {
                    let urlId = (video.url).substring(17, (video.url).length);
                    let url = `https://www.youtube.com/embed/${urlId}?rel=0&amp;fs=0&amp;showinfo=0`
                    let embed = 
                                <iframe 
                                    width="100%" 
                                    height="auto" 
                                    src={url}
                                    title={video.title} 
                                    frameBorder="0" 
                                    
                                    allowFullScreen>
                                </iframe>;
                    if(video.id == id){
                        return(
                            <div className={style.nagranieOne} key={video.id}>
                                <div className={style.breadcrumb}><Link to="/nagrania">Nagrania</Link> <span>/ {video.title} ({video.date})</span></div>
                                <Badge bg="secondary" className={style.timeBadge}>{video.date}</Badge>
                                <h2>{video.title}</h2>
                                <p><b>Opis: </b>{video.desc}</p>
                                { video.timestamps ? 
                                    <div className={style.boxTimeStamp}>
                                        <h5>Przedziały czasowe</h5> 
                                        <Table hover striped bordered style={{marginTop: '10px'}}>
                                            <thead>
                                                <tr>
                                                    <th style={{width: '200px'}}>Czas</th>
                                                    <th>Opis</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                video.timestamps && video.timestamps.map((stamp, index) => {
                                                    return(
                                                        <tr>
                                                            <td>{stamp.time}</td>
                                                            <td>{stamp.desc}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                    </div>
                                : null 
                                }
                                <h5>Nagranie</h5>
                                {embed}
                            </div>
                        )
                    }
                })
            }    
        </Container>
    )
}
