import React from 'react'

import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import style from './Home.module.css'

export default function Home() {
  const sites = [
    {
      name: 'Nagrania',
      url: 'nagrania'
    },
    {
      name: 'Opracowania',
      url: 'opracowania'
    }
  ]

  return (
    <Container className="px-0">
      Backup Studia. Prywatny zbiór pomocy naukowych na studiach.
      <Row style={{marginTop: '25px'}}>
        {
          sites.map((site, index) => {
            return(
              <Col xs={12} md={6} style={{marginBottom: '25px'}} className={style.optionOver} key={index}>
                  <Link to={`/${site.url}`}>
                    <div className={style.optionBox}>
                      <span>{site.name}</span>
                    </div>
                </Link>
              </Col>
            )
          })
        }
      </Row>
    </Container>
  )
}
