import React, { useState, useEffect } from 'react'
import app from '../../firebase'

import style from './EditVideo.module.css'

import { useParams, Link } from 'react-router-dom'

import { Container, Form, Button } from 'react-bootstrap'

export default function EditVideo() {
    const [currentVideo, setCurrentVideo] = useState(null)
    const { id } = useParams()

    useEffect(() => {
        let videos = []
        let refVid = app.database().ref(`videos`)
        refVid.on('value', snapshot => { 
            snapshot.forEach(video => {
                let vid = video.val()
                if(vid.id == id){
                    setCurrentVideo(vid)
                }
            })
        })
    }, [])

    function handleTitleChange(e){
        let tempCurrentVideo = {currentVideo}
        tempCurrentVideo.title = e.target.value
        setCurrentVideo(tempCurrentVideo)
    }

    function handleDescChange(e){
        let tempCurrentVideo = {currentVideo}
        tempCurrentVideo.desc = e.target.value
        setCurrentVideo(tempCurrentVideo)
    }

    function handleDateChange(e){
        let tempCurrentVideo = {currentVideo}
        tempCurrentVideo.date = e.target.value
        setCurrentVideo(tempCurrentVideo)
    }

    function handleTermChange(e){
        let tempCurrentVideo = {currentVideo}
        tempCurrentVideo.termNum = e.target.value
        setCurrentVideo(tempCurrentVideo)
    }

    function handleUrlChange(e){
        let tempCurrentVideo = {currentVideo}
        tempCurrentVideo.url = e.target.value
        setCurrentVideo(tempCurrentVideo)
    }

    function handleEditVideo(e){
        e.preventDefault()
        try{
            app.database().ref(`/videos/${id-1}`).update(currentVideo)
            alert('Zapisano zmiany poprawnie.')
        }
        catch{
            alert('Wystąpił błąd podczas edycji nagrania.')
        }
    }

    return (
        <Container className="px-0">
            <Link to="/update">Cofnij do listy filmów</Link>
            <h2 className="mt-3">Edytuj video #{id}</h2>
            <Form className="mt-3" onSubmit={handleEditVideo}>
                <Form.Group className="mb-3">
                    <Form.Label>Przedmiot</Form.Label>
                    <Form.Control type="text" value={currentVideo && currentVideo.title} onChange={handleTitleChange} placeholder="Podaj przedmiot (kategoria)" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Semestr</Form.Label>
                    <Form.Control type="number" value={currentVideo && currentVideo.termNum} onChange={handleTermChange} placeholder="Podaj semestr" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Data</Form.Label>
                    <Form.Control type="text" value={currentVideo && currentVideo.date} onChange={handleDateChange} placeholder="Podaj datę" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Opis</Form.Label>
                    <Form.Control type="text" value={currentVideo && currentVideo.desc} onChange={handleDescChange} placeholder="Podaj opis" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Link do video</Form.Label>
                    <Form.Control type="text" value={currentVideo && currentVideo.url} onChange={handleUrlChange} placeholder="Podaj link url do video" />
                </Form.Group>
                <Button variant="dark" type="submit">Zapisz</Button>
            </Form>
        </Container>
    )
}
