import React, { useState, useEffect } from 'react'
import Filters from '../../components/Filters/Filters'
import app from '../../firebase'

import style from './Nagrania.module.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

import { Container, Table } from 'react-bootstrap'

import { Link } from 'react-router-dom'

export default function Nagrania({categories, handleChooseCat, init, handleChooseTerm, termsNum, videosDB}) {
  const [showingVideos, setShowingVideos] = useState(0)
  const [videos, setVideos] = useState(null)

  useEffect(() => {
    let videosT = []
    let refVid = app.database().ref(`videos`)
    refVid.on('value', snapshot => { 
      snapshot.forEach(video => {
        let vid = video.val()
        videosT.push(vid)
      })
      let tempVideosDB = []
      let count = 0
      if(init.all && init.terms[0] === 'wszystkie'){
        videosT && videosT.map(video => {
          count++
          tempVideosDB.push(video)
        })
      }
      else{
        videosT && videosT.map(video => {
          if(init.all){
            init.terms.map(term => {
              if(term === video.termNum){
                if(video.termNum === term){
                  count++
                  tempVideosDB.push(video)
                }
              }
            })
          }
          else{
            return(
              categories && categories.map(cat => {
                if(init.terms[0] === 'wszystkie'){
                  if((video.title === cat.name) && cat.choosen){
                    count++
                    tempVideosDB.push(video)
                  }
                }
                else{
                  init.terms.map(term => {
                    if(term === video.termNum){
                      if((video.title === cat.name) && cat.choosen){
                        count++
                        tempVideosDB.push(video)
                      }
                    }
                  })
                }
              })
            )
          }
        })
      }
      tempVideosDB.reverse()
      setVideos(tempVideosDB)
      setShowingVideos(count)
    })
  }, [handleChooseCat, handleChooseTerm])

  return (
    <Container className="px-0">
      <h2>Nagrania</h2>
      <div className={style.termNumBox}>
        <h5>Semestr: </h5>
        <div className={style.termNumInputs}>
          <div onClick={() => handleChooseTerm('wszystkie')} className={`${style.termNumOption} ${init.terms.indexOf('wszystkie') > -1 ? style.choosen : ''}`}>
            Wszystkie
          </div>
          {
            termsNum && termsNum.map((term, index) => {
              return(
                <div onClick={() => handleChooseTerm(term)} className={`${style.termNumOption} ${init.terms.indexOf(term) > -1 ? style.choosen : ''}`} key={index}>
                  {term}
                </div>
              )
            })
          }
        </div>
      </div>
      <Filters categories={categories} handleChooseCat={handleChooseCat} init={init} />
      <div className={style.allBox}>
        <h6>Wyszukane filmy w liczbie: {showingVideos}</h6>
        <div className={style.videosBlock}>
          <Table responsive bordered hover striped className="w-100">
            <thead>
              <tr>
                <th>Data</th>
                <th className="text-center">Semestr</th>
                <th>Tytuł</th>
                <th>Opis</th>
                <th className="text-center">Timestamps</th>
                <th className="text-center">Zobacz</th>
              </tr>
            </thead>
            <tbody>
            {
              init.all ? 
              videos && videos.map((video, index) => {
                return (
                  <tr key={index}>
                    <td>{video.date}</td>
                    <td className="text-center">{video.termNum}</td>
                    <td>{video.title}</td>
                    <td>{video.desc}</td>
                    <td className="text-center">
                      {
                        video.timestamps ?
                        <FontAwesomeIcon icon={faCheck} /> :
                        <FontAwesomeIcon icon={faXmark} />
                      }
                    </td>
                    <td className="text-center">
                      <Link to={`/nagranie/${video.id}`}>
                        <FontAwesomeIcon icon={faPlay} />
                      </Link>
                    </td>
                  </tr>
                )
              })
              :
              videos && videos.map(video => {
                return(
                  categories && categories.map((cat,index) => {
                    if((video.title === cat.name) && cat.choosen){
                      return(
                        <tr key={index}>
                          <td>{video.date}</td>
                          <td className="text-center">{video.termNum}</td>
                          <td>{video.title}</td>
                          <td>{video.desc}</td>
                          <td className="text-center">
                            {
                              video.timestamps ?
                              <FontAwesomeIcon icon={faCheck} /> :
                              <FontAwesomeIcon icon={faXmark} />
                            }
                          </td>
                          <td className="text-center">
                            <Link to={`/nagranie/${video.id}`}>
                              <FontAwesomeIcon icon={faPlay} />
                            </Link>
                          </td>
                        </tr>
                      )
                    }
                  })
                )
              })
            }
            </tbody>  
          </Table>
        </div>
      </div>
    </Container>
  )
}
