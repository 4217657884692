import React, { useState } from 'react'

import style from './Laby.module.css'

import { Container, Row, Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

export default function Laby() {
    const [links, setLinks] = useState([
        {
            num: 1,
            name: 'CNC',
            link: 'https://docs.google.com/document/d/1BIS5Fatr-OYJ7uYXn-Pzq3kDxzg6AnC-/edit?usp=sharing',
            done: true,
            send: false,
            score: null,
            desc: 'ja i tak nie moge wysłać bo mnie nie było :c'
        },
        {
            num: 2,
            name: 'Skaner 3D',
            link: 'https://docs.google.com/document/d/1HfFYwHMd4Bs9e653SIc97SSNahbHeoGi/edit?usp=sharing',
            done: true,
            send: true,
            score: null,
            desc: ''
        },
        {
            num: 3,
            name: 'Drukarka 3D',
            link: 'https://docs.google.com/document/d/1N-dsYp3iNhgZBTRi1-9tJU9vZcNbMwncQOSYsyU-dfI/edit?usp=sharing',
            done: true,
            send: true,
            score: 8,
            desc: 'w sprawozdaniu must be: zasada działania każdej metody druku'
        },
        {
            num: 4,
            name: 'Lego Mindstorms',
            link: 'nie ma',
            done: false,
            send: false,
            score: null,
            desc: 'wejściówka done, sprawozdanie się zapomniało i do wysłania na koniec semestru'
        },
        {
            num: 5,
            name: 'RFID',
            link: 'https://docs.google.com/document/d/1R-bOhWNuYiA3To2MPAiUH90adN52Tfjm_2sVy3IJ018/edit?usp=sharing',
            done: true,
            send: true,
            score: 6,
            desc: null
        },
        {
            num: 6,
            name: 'Eyetracking',
            link: 'https://docs.google.com/document/d/1WtOJsDWXlFt-8Z4odZi4eQwt4zbBepFL/edit?usp=sharing',
            done: true,
            send: true,
            score: 8,
            desc: null
        },
        {
            num: 7,
            name: 'Spawanie',
            link: 'https://docs.google.com/document/d/1DcHO8UN_Sps8jImqQM8Jxr2mP3_2ArbOu4-JT-LfjAo/edit?usp=sharing',
            done: true,
            send: true,
            score: null,
            desc: null
        }
    ])

    return (
        <Container className="px-0">
        <h2>Laboratorium nowych technologii - docs</h2>
        <Row className={style.labyBox}>
          <Table responsive bordered hover striped className="w-100">
            <thead>
              <tr>
                <th className="text-center" style={{width: '75px'}}>#</th>
                <th className="text-center">Temat</th>
                <th className="text-center">Link</th>
                <th className="text-center">Dodatkowy opis</th>
                <th className="text-center" style={{width: '100px'}}>Skończone?</th>
                <th className="text-center" style={{width: '100px'}}>Wysłane?</th>
                <th className="text-center" style={{width: '100px'}}>Ocena</th>
              </tr>
            </thead>
            <tbody>
              {
                links.map((link, index) => {
                  return(
                    <tr key={index}>
                        <td className="text-center">
                          {link.num}
                        </td>
                        <td>
                          {link.name}
                        </td>
                        <td>
                          {
                            (link.link).indexOf('http') >= 0 ? 
                            <a target="_blank" href={link.link} className={style.labyLink}>{(link.link).substr(0, 30)}...</a> : 
                            link.link
                          }
                        </td>
                        <td>
                          {
                            link.desc ? 
                            link.desc : 
                            'nie ma'
                          }
                        </td>
                        <td className='text-center'>
                          {
                            link.done ?
                            <FontAwesomeIcon style={{color: 'green'}} icon={faCheck} /> :
                            <FontAwesomeIcon style={{color: 'red'}} icon={faXmark} />
                          }
                        </td>
                        <td className='text-center'>
                          {
                            link.send ? 
                            <FontAwesomeIcon style={{color: 'green'}} icon={faCheck} /> :
                            <FontAwesomeIcon style={{color: 'red'}} icon={faXmark} />
                          }
                        </td>
                        <td className='text-center'>
                          {
                            link.score ? 
                            <strong>{link.score} / 8</strong> : 
                            'brak'
                          }
                        </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Row>
    </Container>
    )
}
