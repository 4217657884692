import React, { useEffect, useRef, useState } from 'react'
import app from '../../firebase'

import style from './AddVideo.module.css'

import { Link } from 'react-router-dom'

import { Container, Table, Form, Button, Row, Col } from 'react-bootstrap'

export default function AddVideo() {
    const [currentCats, setCurrentCats] = useState(null)
    const [newTimestamps, setNewTimestamps] = useState([])
    const refPrzedmiot = useRef()
    const refTerm = useRef()
    const refDate = useRef()
    const refDesc = useRef()
    const refUrl = useRef()
    const refSelect = useRef()
    const refTimestampTime = useRef()
    const refTimestampDesc = useRef()
    
    useEffect(() => {
        let cCats = []
        let refVid = app.database().ref(`videos`)
        refVid.on('value', snapshot => { 
            snapshot.forEach(video => {
                let vid = video.val()
                if(cCats.indexOf(vid.title) < 0) cCats.push(vid.title)
            })
            setCurrentCats(cCats)
        })
    }, [])

    function handleChangeCat(){
         if(refPrzedmiot.current.value != ''){
             refSelect.current.value = "none"
         }
    }

    function handleChangeCatSel(){
        if(refSelect.current.value != 'none'){ 
            refPrzedmiot.current.value = ''
        }
    }

    function handleRemoveTimestamp(id){
        console.log(`remove ${id}`)
    }

    function handleAddTimestamps(){
        let tempCurrentTimestamps =  null
        if(newTimestamps) tempCurrentTimestamps = newTimestamps
        else tempCurrentTimestamps = [] 

        try{
            if(refTimestampTime.current.value != '' && refTimestampDesc.current.value != ''){
                let tempObjt = {
                    desc: refTimestampDesc.current.value,
                    time: refTimestampTime.current.value
                }
                tempCurrentTimestamps.push(tempObjt)
                refTimestampTime.current.value = '' 
                refTimestampDesc.current.value = ''
            }
            else{ 
                alert('Uzupełnij wszystkie pola.') 
            }
        }
        catch{
            alert('Something went error...')
        }
        console.log(tempCurrentTimestamps)
        setNewTimestamps(tempCurrentTimestamps)
    }

    function handleAddVideo(e){
        e.preventDefault();

        let title = refPrzedmiot.current.value

        if(refPrzedmiot.current.value == ''){
            title = refSelect.current.value
        } 

        try{
            let id = Date.now() 
            let dataToPush = null
            if(newTimestamps.length > 0){
                dataToPush = {
                    id: id,
                    date: refDate.current.value,
                    desc: refDesc.current.value,
                    priority: 1,
                    termNum: parseInt(refTerm.current.value),
                    title: title,
                    url: refUrl.current.value,
                    timestamps: newTimestamps
                }
            }
            else{
                dataToPush = {
                    id: id,
                    date: refDate.current.value,
                    desc: refDesc.current.value,
                    priority: 1,
                    termNum: parseInt(refTerm.current.value),
                    title: title,
                    url: refUrl.current.value
                }
            }
            refDate.current.value = ''
            refDesc.current.value = ''
            refTerm.current.value = ''
            refUrl.current.value = ''
            refTimestampTime.current.value = ''
            refTimestampDesc.current.value = ''
            refPrzedmiot.current.value = ''
            setNewTimestamps(null)
            app.database().ref(`videos/${id}`).set(dataToPush)
            alert('dodano nowy film poprawnie.')
        }
        catch{
            alert('coś poszło nie tak...')
        }
    }

    return (
        <Container className="px-0">
            <Link to="/update">Cofnij do listy filmów</Link>
            <h2>Dodaj nowy film</h2>
            <Form className="mt-3" onSubmit={handleAddVideo}>
                    <Form.Group className="mb-3">
                        <Form.Label>Przedmiot</Form.Label><br />
                        <Form.Label className="mt-2">Wybierz z instniejących</Form.Label>
                        <Form.Select ref={refSelect} onChange={handleChangeCatSel}>
                            <option disabled value="none" selected>Rozwiń listę</option>
                            {
                                currentCats && currentCats.map((cat, index) => {
                                    return <option value={cat} key={index}>{cat}</option>
                                })
                            }
                        </Form.Select>
                        <Form.Label className="mt-2">Wpisz nowy</Form.Label>
                        <Form.Control type="text" ref={refPrzedmiot} onChange={handleChangeCat} placeholder="Podaj przedmiot (kategoria)" />
                        <hr />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Semestr</Form.Label>
                        <Form.Control type="number" ref={refTerm} placeholder="Podaj semestr" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Data</Form.Label>
                        <Form.Control type="text" ref={refDate} placeholder="Podaj datę" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Opis</Form.Label>
                        <Form.Control type="text" ref={refDesc} placeholder="Podaj opis" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Link do video</Form.Label>
                        <Form.Control type="text" ref={refUrl} placeholder="Podaj link url do filmu" required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Dodaj znacznik czasowy</Form.Label>
                        <Table responsive bordered hover striped className="w-100">
                            <thead>
                                <tr>
                                    <th>Czas</th>
                                    <th>Opis</th>
                                    <th>Usuń?</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                newTimestamps.map((stamp, index) => {
                                    return( 
                                        <tr>
                                            <td>{stamp.time}</td>
                                            <td>{stamp.desc}</td>
                                            <td onClick={() => handleRemoveTimestamp(index)}>usuń</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        <Row>
                            <Col xs={12} md={5}>
                                <Form.Label>Czas</Form.Label>
                                <Form.Control type="text" ref={refTimestampTime} placeholder="Podaj czas znacznika" />
                            </Col>
                            <Col xs={12} md={5}>
                                <Form.Label>Opis</Form.Label>
                                <Form.Control type="text" ref={refTimestampDesc} placeholder="Podaj opis znacznika" />
                            </Col>
                            <Col xs={12} md={2}>
                                <Button variant="dark" className="w-100 mt-3 mt-md-0" style={{height: '100%'}} onClick={handleAddTimestamps}>Dodaj znacznik</Button>    
                            </Col>
                        </Row>
                    </Form.Group>
                    <Button variant="dark" type="submit" className="mt-3 w-100">Dodaj</Button>
                </Form>
        </Container>
    )
}
