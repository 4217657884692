import './App.css';
import React, { useEffect, useState } from 'react'
import app from './firebase'

import { Container, Row, Col } from 'react-bootstrap'
import { Switch, Route, withRouter, BrowserRouter as Router } from 'react-router-dom'

import { AuthProvider } from './contexts/AuthContext';

import Home from './views/Home/Home'
import Nagrania from './views/Nagrania/Nagrania'
import Sidebar from './components/Sidebar/Sidebar';
import NagranieOne from './views/NagranieOne/NagranieOne';
import Opracowania from './views/Opracowania/Opracowania';
import Laby from './views/Laby/Laby';
import OpracowanieOne from './views/OpracowanieOne/OpracowanieOne'
import Admin from './views/Admin/Admin'
import Update from './views/Update/Update';
import EditVideo from './views/EditVideo/EditVideo';
import PrivateRoute from './views/PrivateRoute';

import ReactGA from 'react-ga';
import AddVideo from './views/AddVideo/AddVideo';

const TRACKING_ID = 'UA-51521950-22';
ReactGA.initialize(TRACKING_ID);

function App() { 
  const [termsNum, setTermsNum] = useState([])
  const [allVideos, setAllVideos] = useState(null)
  const [defualtTerm, setDefaultTerm] = useState(5)
  const [videoCategories, setVideoCategories] = useState({
    init: {
      all: true,
      terms: [defualtTerm]
    },
    categories: []} 
  )

  useEffect(() => {
    let videos = []
    let refVid = app.database().ref(`videos`)
    refVid.on('value', snapshot => { 
      snapshot.forEach(video => {
        let vid = video.val()
        videos.push(vid)
      })
      let tempVidCats = {}
      let tempTermsNums = [defualtTerm]
      tempVidCats.init = {all: true, terms: [defualtTerm]}
      let tempCats = []
      videos.map(video => {
        if(tempTermsNums.indexOf(video.termNum) < 0) tempTermsNums.push(video.termNum)
        if(tempCats.length === 0) tempCats.push(video.title)
        else{
          if(tempCats.indexOf(video.title) === -1) tempCats.push(video.title)
        }
      })
      let tempCatObj = []
      tempCats.map((cat, index) => {
        let tempOneCatObj = {
          id: index,
          choose: false,
          name: cat
        }
        tempCatObj.push(tempOneCatObj)
      })
      tempVidCats.categories = tempCatObj
      setVideoCategories(tempVidCats)
      setTermsNum(tempTermsNums)
      setAllVideos(videos)
    }) 
  }, [])

  function handleChooseTerm(term){
    const newVideoInit = {...videoCategories}
    if(term === 'wszystkie'){
      newVideoInit.init.terms = ['wszystkie']
    }
    else{
      if(newVideoInit.init.terms.length === 0) newVideoInit.init.terms.push(term)
      else{
        newVideoInit.init.terms.map(termIn => {
          if(newVideoInit.init.terms.indexOf('wszystkie') >= 0){
            newVideoInit.init.terms.splice(newVideoInit.init.terms.indexOf('wszystkie'), 1)
          }
          if(newVideoInit.init.terms.indexOf(term) < 0){
            newVideoInit.init.terms.push(term)
          }
          else{
            newVideoInit.init.terms.splice(newVideoInit.init.terms.indexOf(term), 1)
          }
        })
      }
    }
    setVideoCategories(newVideoInit)
  }

  function handleChooseCat(cat){
    let countChoosen = 0;

    videoCategories.categories.map(cat => {
      if(!videoCategories.init.all) if(cat.choosen) countChoosen++
    })

    if(videoCategories.init.all || countChoosen >= 0){
      if(cat === 'all'){
        const newVideoCategories = {...videoCategories}
        newVideoCategories.init.all = !newVideoCategories.init.all
        newVideoCategories.categories.map(cat => {
          cat.choosen = false
        })
        setVideoCategories(newVideoCategories)
      }
      else{
        const newVideoCategories = {...videoCategories}
        newVideoCategories.init.all = false
        newVideoCategories.categories[cat.id].choosen = !newVideoCategories.categories[cat.id].choosen
        setVideoCategories(newVideoCategories)
      }
    }
  }

  let windowLocation = window.location.pathname;
  ReactGA.pageview(windowLocation + window.location.search);

  return (
    <Container fluid style={{minHeight: '100vh'}}>
      <Router>
          <Row style={{minHeight: '100vh'}}>
            <Col xs={2} className="px-0">
              <Sidebar />
            </Col>
            <Col xs={10} className="px-4 py-4">
                <Switch>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/nagrania">
                    <Nagrania termsNum={termsNum} handleChooseTerm={handleChooseTerm} categories={videoCategories.categories} init={videoCategories.init} handleChooseCat={handleChooseCat} />
                  </Route>
                  <Route path="/nagranie/:id">
                    <NagranieOne videos={allVideos} />
                  </Route>
                  <Route path="/opracowania" component={Opracowania} />
                  <Route path="/laby" component={Laby} />
                  <Route path="/opracowanie/:name" component={OpracowanieOne} />
                  <AuthProvider>
                    <Route path="/admin" component={Admin} />
                    <PrivateRoute path="/update" component={Update} />
                    <PrivateRoute path="/editVideo/:id" component={EditVideo} />
                    <PrivateRoute path="/addVideo" component={AddVideo} />
                  </AuthProvider>
                </Switch>
            </Col>
          </Row>
      </Router>
    </Container>
  );
}

export default withRouter(App);
