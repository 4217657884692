import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {vscDarkPlus} from 'react-syntax-highlighter/dist/esm/styles/prism'

import style from './OpracowanieOne.module.css'

import siszarp from '../../md/siszarp.md'
import db from '../../md/db.md'
import db2 from '../../md/db2.md'

import zadaniaDB2 from '../../assets/plpgsql.pdf'

import { Container, Badge } from 'react-bootstrap'

import { useParams, Link } from 'react-router-dom'

export default function OpracowanieOne() {
    const { name } = useParams()
    const [mark, setMark] = useState('')

    useEffect(() => {
      if(name == 'siszarp') fetch(siszarp).then(res => res.text()).then(text => setMark(text))
      if(name == 'db') fetch(db).then(res => res.text()).then(text => setMark(text))
      if(name == 'db2') fetch(db2).then(res => res.text()).then(text => setMark(text))
    })

    return (
      <Container className="px-0">
        <div className={style.breadcrumb}>
          <Link to="/opracowania">Opracowania</Link><span> / {name}</span>
        </div>
        {
          name == 'db2' ? 
          <Badge Badge bg="secondary" style={{fontSize: '14px', fontWeight: '400', margin: '10px 0px'}}>
            Pomoc naukowa: <a href={zadaniaDB2} target="_blank" style={{color: 'white', textDecoration: 'underline'}}>kliknij</a>
          </Badge> : 
          null
        }
        <div className={style.opracowanieOneBok}>
          <ReactMarkdown 
            children={mark}
            components={{
              code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={vscDarkPlus}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              }
            }}
          />
        </div>
      </Container>
    )
}
