import React from 'react'

import style from './Opracowania.module.css'

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'

import { Container, Row, Table } from 'react-bootstrap'

export default function Opracowania() {
  const opracowania = [
    {
      name: 'Bazy danych (semestr 4)',
      file: 'db2',
      created: '07.03.2022'
    },
    {
      name: 'Bazy danych (semestr 3)',
      file: 'db',
      created: '21.01.2022'
    },
    {
      name: 'C#',
      file: 'siszarp',
      created: '03.01.2022'
    }
  ]

  return (
    <Container className="px-0">
        <h2>Opracowania</h2>
        <Row className={style.opracowaniaBox}>
          <Table responsive bordered hover striped className="w-100">
            <thead>
              <tr>
                <th className="text-center" style={{width: '75px'}}>#</th>
                <th>Temat</th>
                <th>Utworzono</th>
                <th className="text-center" style={{width: '150px'}}>Zobacz</th>
              </tr>
            </thead>
            <tbody>
              {
                opracowania.map((op, index) => {
                  return(
                    <tr key={index}>
                        <td className="text-center">
                          {index+1}
                        </td>
                        <td>
                          {op.name}
                        </td>
                        <td>
                          {op.created}
                        </td>
                        <td className="text-center">
                          <Link to={`opracowanie/${op.file}`}>
                            <FontAwesomeIcon icon={faBookmark} /> 
                            <span>Czytaj</span>
                          </Link>
                        </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Row>
    </Container>
  )
}
