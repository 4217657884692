import React, { useState, useEffect } from 'react'
import app from '../../firebase'
import style from './Update.module.css'
import { useAuth } from '../../contexts/AuthContext'

import { Container, Table, Button } from 'react-bootstrap'

import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

export default function Update() {
  const [allVideos, setAllVideos] = useState(null)
  const { logout } = useAuth()
  const history = useHistory()

  useEffect(() => {
    let videos = []
    let refVid = app.database().ref(`videos`)
    refVid.on('value', snapshot => { 
      snapshot.forEach(video => {
        let vid = video.val()
        videos.push(vid)
      })
    })
    setAllVideos(videos)
  }, [allVideos])

  function test(){
    console.log(allVideos)
  }

  function deleteVideo(id){
    if (window.confirm('Na pewno chcesz usunąć wpis?')) {
      let tempAllVideos = allVideos
      tempAllVideos.map(video => {
        if(video.id == id){
          tempAllVideos.splice(tempAllVideos.indexOf(video), 1)
        }
      })
      try{
        app.database().ref(`/`).update({
          videos: tempAllVideos
        }) 
        setAllVideos(tempAllVideos)
        alert('Poprawnie usunięto film z bazy danych.')
      }
      catch{
        alert('Wystąpił błą podczas usuwania filmu z bazy danych.')
      }
    } else {
      console.log(`anulowano usuniecie filmu o id: ${id}`)
    }
    
  }

  async function logoutFromAdmin(){
    try{
      await logout()
      history.push('/')
    } catch(e){
        console.log(e)
    }
  } 

  return (
    <Container className="px-0">
      <h2>Panel admina</h2>
      <h6>Aktualizuj bazę danych filmów poniżej.</h6>
      <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
        <Link to='/addVideo'><Button variant="dark">Dodaj nowy film</Button></Link>
        <Button className={style.logoutBtn} variant="dark" onClick={logoutFromAdmin}>Wyloguj</Button>
      </div>
      <Table responsive bordered hover striped className="w-100 mt-3">
      <thead>
        <tr>
          <th>#</th>
          <th>Data</th>
          <th>Semestr</th>
          <th>Przedmiot</th>
          <th>Opis</th>
          <th>Edytuj</th>
          <th>Usuń</th>
        </tr>
      </thead>
      <tbody>
      {
        allVideos && allVideos.map((video, index) => {
          return(
            <tr>
              <td>{index+1}</td>
              <td>{video.date}</td>
              <td>{video.termNum}</td>
              <td>{video.title}</td>
              <td>{video.desc}</td>
              <td> 
                <Link to={`/editVideo/${video.id}`}>Edytuj</Link>
              </td>
              <td>
                <span onClick={() => deleteVideo(video.id)} style={{color: '#0d6efd', textDecoration: 'underline', cursor: 'pointer'}}>Usuń</span>
              </td>
            </tr>
          )
        })
      }
      </tbody>
      </Table>
    </Container>
  )
}
