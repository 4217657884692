import React, { useState } from 'react'

import style from './Filter.module.css'

export default function Filter({cat, handleChooseCat, init}) {
  if(init){
    return(
      <div className={`${style.filter} ${init.all ? style.choosen : ''}`} onClick={() => handleChooseCat('all')}>
        Wszystko
      </div>
    )
  }
  return (
    <div className={`${style.filter} ${cat.choosen ? style.choosen : ''}`} key={cat.id} onClick={() => handleChooseCat(cat)}>
      {cat.name}
    </div>
  )
}
