import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDTAD-bwTyXTbQmuaJDQ9I6WL1IeFzkhF4",
    authDomain: "backupstudia-30c5f.firebaseapp.com",
    databaseURL: "https://backupstudia-30c5f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "backupstudia-30c5f",
    storageBucket: "backupstudia-30c5f.appspot.com",
    messagingSenderId: "650544649994",
    appId: "1:650544649994:web:283404f12efc0556cbccf3"
  };

const app = firebase.initializeApp(firebaseConfig)

export default app