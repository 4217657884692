import React from 'react'

import { Nav } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faClapperboard, faFolder } from '@fortawesome/free-solid-svg-icons'

import style from './Sidebar.module.css'

export default function () {
  const { location } = useHistory()

  return (
    <div style={{height: '100vh', position: 'fixed', width: 'inherit'}} className="bg-dark text-white d-flex align-items-start justify-content-between flex-column">
        <div className='w-100 p-0'>
          <span className={style.logo}>
            <Link to="/">
              <div className="d-none d-md-block">
                Studia<span>Backup</span>
              </div>
              <div className="d-block d-md-none">
                S<span>B</span>
              </div>
            </Link>
          </span>
          <Nav activeKey={location.pathname} className="px-0 d-flex flex-column flex-wrap justify-content-start align-items-start">
            <Nav.Item className={style.navItem}>
                <Nav.Link as={Link} to="/" className="p-0">
                  <span className="d-flex d-md-none">
                    <FontAwesomeIcon icon={faHome} />
                  </span>
                  <span className="d-none d-md-flex">
                    Start
                  </span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={style.navItem}>
                <Nav.Link as={Link} to="/nagrania" className="p-0">
                  <span className="d-flex d-md-none">
                    <FontAwesomeIcon icon={faClapperboard} />
                  </span>
                  <span className="d-none d-md-flex">
                    Nagrania
                  </span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={style.navItem}>
                <Nav.Link as={Link} to="/opracowania" className="p-0">
                  <span className="d-flex d-md-none">
                    <FontAwesomeIcon icon={faFolder} />
                  </span>
                  <span className="d-none d-md-flex">
                    Opracowania
                  </span>
                </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className={`${style.footer} w-100 justify-content-center d-flex p-2`} style={{opacity: 0.5}}>
          <Link to="/admin"><span>&copy; <span className="d-none d-md-inline-block">Backup Studia</span> 2022</span></Link>
        </div>
    </div>
  )
}
