import React from 'react'

import style from './Filters.module.css'
import Filter from '../Filter/Filter'

export default function Filters({categories, handleChooseCat, init}) {
    return (
        <div className={style.filters}>
            <h5>Przedmiot: </h5>
            <Filter init={init} handleChooseCat={handleChooseCat} />
            {
                categories && categories.map(cat => {
                    return <Filter key={cat.id} handleChooseCat={handleChooseCat} cat={cat} />
                })
            }
        </div>
    )
}
