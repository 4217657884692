import React, { useEffect, useRef, useState } from 'react'

import { Container, Form, Button, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

import style from './Admin.module.css'

export default function Admin() {
  const history = useHistory()
  const emailRef = useRef()
  const passRef = useRef()
  const { login, currentUser } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)


  async function handleSubmit(e){
    e.preventDefault()
    try{
        setError('')
        setLoading(true)
        await login(emailRef.current.value, passRef.current.value)
        history.push('/update')
    } catch(e){
      setError('# error')
    }
    setLoading(false)
  }

  useEffect(() => {
    if(currentUser) history.push('/update')
  }, [])

  return (
    <Container className="px-0">
      <h2>Panel administratora</h2>
      {error && <Alert variant="danger" className='mt-4'>{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mt-4">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" ref={emailRef} required placeholder='Podaj email administratora' />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label>Hasło</Form.Label>
          <Form.Control type="password" ref={passRef} required placeholder="Podaj hasło do konta administratora" />
        </Form.Group>
        <Button disabled={loading} type="submit" variant="dark" className="mt-4 w-100">Zaloguj</Button>
      </Form>
    </Container>
  )
}
